import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/municipalities.scss';
// import CustomCarousel from '../components/carousel';

const Municipalities = () => {
  return (
    <Layout>
      <SEO
        title="Nutrient Recovery Solutions for Municipalities & Wastewater Treament | Ostara"
        description="Learn how to upcycle excess phosphorus and nitrogen in your municipal or industrial waterway to create a sustainable, revenue-generating fertilizer."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Nutrient Recovery Solutions for Municipalities"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400} marginBottom={[20, 20, 20, 100]}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box w={['100%', '100%', '100%', '48%']}>
                <Stack marginTop={[0, 0, 20, 20]} spacing={6}>
                  <h1>
                    Nutrient Recovery Solutions for Municipalities, Industries &
                    More
                  </h1>
                  <p style={{ maxWidth: 400 }}>
                    Upcycle phosphorus in your water streams into sustainable,
                    revenue-generating fertilizer.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/municipalities-hero-img.png"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Box
          w="100%"
          p={4}
          className="municipalities-body-container light-blue-bg"
        >
          <Container
            className="municipalities-content-1--container"
            maxW={1400}
          >
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              spacing={6}
              className="municipalities-content-1--data"
            >
              <Box>
                <StaticImage
                  className="hero-img"
                  placeholder="transparent"
                  src="../images/Ostara_Technology.png"
                  alt="Ostara hero"
                />
              </Box>
              <Box className="municipalities-content-1--text">
                <h3>A Complete Nutrient Recovery Solution</h3>

                <p>
                  Ostara’s nutrient recovery solutions enable wastewater
                  treatment leaders to convert nutrient management challenges
                  into revenue-generating fertilizer and a cost-effective
                  opportunity for environmental stewardship.
                </p>
              </Box>
            </SimpleGrid>
          </Container>
          <Container marginTop={50} maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6}>
              <Box className="municipalities-card">
                <h1>01</h1>
                <p>
                  Install Nutrient Recovery Technology in Your Water Treatment
                  Center
                </p>
                <div className="rounded-img">
                  <StaticImage
                    placeholder="transparent"
                    src="../images/pearl2.png"
                    alt="Ostara hero"
                    width={300}
                    height={300}
                  />
                </div>
              </Box>
              <Box className="municipalities-card">
                <h1>02</h1>
                <p>
                  Recover Nutrients While Saving on Operations and Chemicals
                </p>
                <div className="rounded-img">
                  <StaticImage
                    placeholder="transparent"
                    src="../images/pearl1.png"
                    alt="Ostara hero"
                    width={300}
                    height={300}
                  />
                </div>
              </Box>
              <Box className="municipalities-card">
                <h1>03</h1>
                <p>
                  Recovered Nutrients Distributed as Crystal Green Pearl
                  Fertilizer
                </p>
                <div className="rounded-img">
                  <StaticImage
                    placeholder="transparent"
                    src="../images/pearl3.png"
                    alt="Ostara hero"
                    width={300}
                    height={300}
                  />
                </div>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="municipalities-content-2">
          <Container
            className="municipalities-content-2--container"
            maxW={1400}
          >
            <div className="municipalities-content-2--heading">
              <h2>Why Join the Nutrient Recovery Movement?</h2>
            </div>

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={[10, 20]}>
              <Box w="100%">
                <StaticImage
                  placeholder="transparent"
                  src="../images/cleanwater.png"
                  alt="Ostara img"
                />
              </Box>

              <Flex direction="column" justifyContent="center" w="100%">
                <div className="technology-content-1--wrapper">
                  <h3>Cleaner Water</h3>
                  <p>
                    Nutrient pollution can harm waterways, creating algae blooms
                    that upset the natural environment. Ostara helps maintain
                    nutrient levels to sustain a healthy water supply.
                  </p>
                </div>
              </Flex>
            </SimpleGrid>

            <SimpleGrid
              marginTop={20}
              columns={{ sm: 1, md: 2 }}
              spacing={[10, 20]}
            >
              <Flex direction="column" justifyContent="center" w="100%">
                <div className="technology-content-2--wrapper">
                  <h3>Greener Operations</h3>
                  <p>
                    Using our sustainable solutions to remove nutrients, you can
                    also reduce energy use, chemical demand, and waste sludge
                    volumes for additional environmental benefits.
                  </p>
                </div>
              </Flex>
              <Box w="100%">
                <StaticImage
                  placeholder="transparent"
                  src="../images/Ostara_wwtp_1080px.png"
                  alt="Ostara img"
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid
              marginTop={20}
              columns={{ sm: 1, md: 2 }}
              spacing={[10, 20]}
            >
              <Box w="100%">
                <StaticImage
                  placeholder="transparent"
                  src="../images/Ostara_Technology_WWTP_2000px-2.png"
                  alt="Ostara img"
                />
              </Box>

              <Flex direction="column" justifyContent="center" w="100%">
                <div className="technology-content-1--wrapper">
                  <h3>Improved ROI</h3>
                  <p>
                    When you invest in our nutrient recovery solution, capital
                    costs are recouped in 5-10 years through savings in
                    chemicals, sludge disposal, and maintenance while also
                    creating revenue from Crystal Green sales.
                  </p>
                </div>
              </Flex>
            </SimpleGrid>
          </Container>

          <Container
            className="municipalities-content-3--container"
            maxW={1400}
          >
            <h2>Your Community Benefits When You Choose Ostara</h2>

            <div className="municipalities-content-3--feature">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl4.png"
                alt="Ostara img"
                width={180}
                height={180}
              />
              <h4>Convert Influent Phosphorus Into Sustainable Fertilizer</h4>
              <Flex direction="column">
                <p>Meet strict P limits more cost effectively</p>
                <p>Improved N:P balance in biosolids</p>
              </Flex>
            </div>
            <hr className="custom-divider municipalities-divider" />
            <div className="municipalities-content-3--feature">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl5.png"
                alt="Ostara img"
                width={180}
                height={180}
              />
              <h4>Reduce Total Biosolids Volume up to 10-20%</h4>
              <Flex direction="column">
                <p>Avoidance of chemical sludge</p>
                <p>Avoidance of struvite in sludge cake</p>
              </Flex>
            </div>
            <hr className="custom-divider municipalities-divider" />
            <div className="municipalities-content-3--feature">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl6.png"
                alt="Ostara img"
                width={180}
                height={180}
              />
              <h4>Improved Dewaterability</h4>
              <Flex direction="column">
                <p>
                  Up to 2% increase in cake solids (corresponding to
                  approximately 10% volume reduction)
                </p>
                <p>Potential for 5-20% polymer reduction</p>
              </Flex>
            </div>
            <hr className="custom-divider municipalities-divider" />
            <div className="municipalities-content-3--feature">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl7.png"
                alt="Ostara img"
                width={180}
                height={180}
              />
              <h4>Reduce Chemical and Maintenance Costs</h4>
              <Flex direction="column">
                <p>Reduce or eliminate chemicals added for P removal</p>
                <p>Avoid chemical sludges</p>
                <p>
                  Offset operational costs with sale of high value fertilizer
                </p>
              </Flex>
            </div>
            <hr className="custom-divider municipalities-divider" />
            <div className="municipalities-content-3--feature">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl8.png"
                alt="Ostara img"
                width={180}
                height={180}
              />
              <h4>Protect Digesters from Nuisance Struvite</h4>
              <Flex direction="column">
                <p>Up to 90% reduction in digester struvite</p>
                <p>Reduced digester maintenance</p>
              </Flex>
            </div>
          </Container>
        </Box>

        <Box
          w="100%"
          p={4}
          className="municipalities-body-container green-bg"
          marginTop={[0, 20, 20, 100]}
        >
          <Container
            className="municipalities-content-4--container"
            maxW={1400}
          >
            <div className="municipalities-content-4--heading">
              <h2>
                The Only Nutrient Management Solution that Delivers Operating,
                Economic and Environmental Benefits
              </h2>
              <p>
                Traditional ways of managing phosphorus to meet effluent limits
                and protect local waterways creates a new problem: struvite.
                Instead of solving this problem with expensive chemicals, Ostara
                has a better way.
              </p>
            </div>
          </Container>
          <Container marginTop={50} maxW={1400}>
            <StaticImage
              placeholder="transparent"
              src="../images/municipalities-table-v2.svg"
              alt="Ostara hero"
              className="municipalities-content-4--table"
            />
          </Container>
        </Box>

        <Box
          w="100%"
          p={4}
          className="municipalities-body-container"
          marginTop={[20, 20, 20, 100]}
        >
          <Container
            className="municipalities-content-5--container"
            maxW={1400}
          >
            <h2>
              Water Managers Around the World Are Saying ‘Yes’ to Sustainable
              Nutrient Management
            </h2>

            <StaticImage
              placeholder="transparent"
              src="../images/municipalities-map.png"
              alt="Ostara hero"
              className="municipalities-content-4--table"
            />
          </Container>
        </Box>

        <Box w="100%" className="municipalities-body-container">
          {/* <Container className="carousel-section" maxW={1400}>
            <h4>Data & Insights</h4>
            <CustomCarousel className="carousel-container" />
          </Container> */}

          <Container className="body-cta-container" maxW={1400}>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default Municipalities;
